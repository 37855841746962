.app {
  margin: 20px;
}

form {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header h1 {
  margin-top: 0;
  margin-bottom: 5px;
}

.sub-header {
  margin-top: 0;
  margin-bottom: 30px;
}

footer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

footer a {
  margin-right: 5px;
  margin-left: 5px;
}

footer a:last-child {
  margin-right: 0;
}

footer a:first-child {
  margin-left: 0;
}

button {
  cursor: pointer;
  white-space: nowrap;
}

form label {
  margin-bottom: 5px;
  font-weight: bold;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  text-align: left;
  padding-bottom: 5px;
  border-bottom: 1px solid black;
}

td {
  padding: 10px 0;
  border-bottom: 1px solid black;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.add-loan-btn,
.calculate-btn {
  margin-bottom: 30px;
}

.payoff-strategy-form {
  justify-content: left;
  align-items: start;
}

.radio-option-group {
  display: flex;
  align-items: center;
  align-content: center;
}

.radio-option-group input {
  margin-right: 5px;
}
.radio-option-group label {
  margin-bottom: 0;
  font-weight: normal;
}

.results-summary {
  display: flex;
  justify-content: space-around;
}

.loan-payoff__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-top: 1px solid black;
}
